<template>
    <v-card>
        <v-app-bar :style="cardTitleBarStyle" flat dense>
            <v-toolbar-title :style="cardTitleBarTextStyle">
                <slot name="title">Communication</slot>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                :color="accentColor"
            ></v-progress-linear> <!-- TODO: use brand accent color instead of green -->
            <v-spacer/>
            <slot name="menu"></slot>
        </v-app-bar>
        <v-divider/>
        <slot/>
    </v-card>
</template>

<script>
import { mapGetters /* , mapState */ } from 'vuex';
// import BrandImage from '@/components/BrandImage.vue';

export default {
    components: {
        // BrandImage,
    },
    data: () => ({
    }),
    computed: {
        // TODO: move this palette stuff to vuex store? because it's also needed in subscribe page, unsubscribe page, etc. and they all share it based on what's in the url...
        // ...mapState({
        //     brand: (state) => state.brand,
        //     palette: (state) => state.palette,
        //     defaultPalette: (state) => state.defaultPalette,
        // }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
        }),
    },
};
</script>
