/*
Copyright (C) 2023 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console */

// intents
const INTENT_LOGIN = 'login';
const INTENT_SIGNUP = 'signup';
const INTENT_PROFILE = 'profile'; // TODO: replace with 'attest'
const INTENT_ATTEST = 'profile'; // TODO: replace constant 'profile' with 'attest'
const INTENT_USER_PROFILE = 'user_profile';
const INTENT_PROFILE_RESPONSE = 'profile_response'; // TODO: replace with 'attest'

// interactions
const IA_LOGIN = 'login';
const IA_SIGNUP = 'signup';
const IA_PROFILE_RESPONSE = 'profile_response';
const IA_VERIFY_EMAIL = 'verify_email';

// routes
const ROUTE_INTENT = 'intent';
const ROUTE_INTERACTION = 'interaction';
const ROUTE_LOGIN = 'login';
const ROUTE_SIGNUP = 'signup';
const ROUTE_PROFILE = 'profile'; // TODO: replace with ROUTE_USER_PROFILE for clarity
const ROUTE_FRONT = 'front';
const ROUTE_PROMPT = 'prompt';
const ROUTE_USER_PROFILE = 'profile';

export {
    INTENT_ATTEST,
    INTENT_LOGIN,
    INTENT_SIGNUP,
    INTENT_PROFILE,
    INTENT_PROFILE_RESPONSE,
    INTENT_USER_PROFILE,
    IA_LOGIN,
    IA_SIGNUP,
    IA_PROFILE_RESPONSE,
    IA_VERIFY_EMAIL,
    ROUTE_INTENT,
    ROUTE_INTERACTION,
    ROUTE_LOGIN,
    ROUTE_SIGNUP,
    ROUTE_PROFILE,
    ROUTE_FRONT,
    ROUTE_PROMPT,
    ROUTE_USER_PROFILE,
};
